@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add any custom styles below */
html {
    scroll-behavior: smooth;
}

body {
    @apply antialiased;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    @apply bg-gray-100 dark:bg-gray-800;
}

::-webkit-scrollbar-thumb {
    @apply bg-orange-500 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-orange-600;
}